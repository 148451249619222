import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import Typography from "./typography";

export default function NavigationBar() {
  const [learnOpen, setLearnOpen] = React.useState<boolean>(false);
  const [partnerOpen, setPartnerOpen] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <>
      <Modal show={!!modalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Body>
          <div>
            <Typography type="subject">Contact</Typography>
            <div>
              <div>
                <div className="link link--page mb-2">Phone: 888-420-8686</div>
              </div>
              <div className="link link--page mb-2">
                Email: questions@fundingverse.com
              </div>
              <div className="link link--page">
                Address: 4470 Chamblee Dunwoody Rd, Atlanta, GA 30338
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Navbar className="fnavbar" variant="dark" expand="lg">
        <Container>
          <div className="hide-on-desktop mx-auto">
            <Navbar.Brand href="/">
              <StaticImage
                src="../images/fundingverse-logo-white.png"
                alt="Fundingverse Logo"
                placeholder="blurred"
                layout="fixed"
                width={200}
                height={50}
              />
            </Navbar.Brand>
          </div>
          <div className="hide-on-mobile">
            <Navbar.Brand href="/">
              <StaticImage
                src="../images/fundingverse-logo-white.png"
                alt="Fundingverse Logo"
                placeholder="blurred"
                layout="fixed"
                width={200}
                height={50}
              />
            </Navbar.Brand>
          </div>
          <div className="text-right hide-on-mobile">
            <Nav className="me-auto">
              <Typography type="navlink" to="/">
                Home
              </Typography>
              <NavDropdown title="Loan Type" id="learn-nav-dropdown">
                <div
                  className="fnavbar-drop"
                  style={{ maxHeight: "316px", overflowY: "scroll" }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/learn/business-line-of-credit"
                  >
                    <Typography type="subject">
                      Business Line of Credit
                    </Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      A business line of credit is a business loan that offers
                      the flexibility to borrow up to a certain credit limit
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learn/working-capital-loan">
                    <Typography type="subject">Working Capital Loan</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      A working capital loan is best used to fund short-term,
                      daily operational expenses such as payroll and purchasing
                      materials.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learn/bank-loan">
                    <Typography type="subject">Bank Loan</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                    Usually the lowest interest rate product, but available to mainly for the lower risk borrowers.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learn/credit-union-loan">
                    <Typography type="subject">Credit Union Loan</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      A Credit Union Loan is much like a bank loan, and requires
                      you become a member of a Union.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                      as={Link}
                      to="/learn/business-long-term-expansion">
                      <Typography type="subject">
                        Business Expansion Loan (Long-Term)
                      </Typography>
                    </NavDropdown.Item> */}
                  <NavDropdown.Item
                    as={Link}
                    to="/learn/invoice-factoring-loan"
                  >
                    <Typography type="subject">
                      Invoice Factoring Loan
                    </Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Invoice Factoring loans are a great way to free up cash
                      flow for small businessess looking to stay afloat and
                      continue ascending.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learn/business-loan">
                    <Typography type="subject">Business Loan</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Business loans are a great way for startup business with
                      little to no history to obtain funding and begin
                      generating revenue.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learn/term-loan">
                    <Typography type="subject">Term Loan</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Depending on the size, term loans can be used for
                      expanding human capital, construction, and inventory
                      purchases.
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>

              <NavDropdown
                title="Partner With Us"
                id="partner-with-us-nav-dropdown"
              >
                <div className="fnavbar-drop">
                  <NavDropdown.Item as={Link} to="/loan-officers">
                    <Typography type="subject">
                      Individual Loan Officers
                    </Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Partner with Fundingverse as an Individual Loan Officer
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/banks">
                    <Typography type="subject">
                      Banks &amp; Credit Unions
                    </Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Learn more about the Bank-Fundingverse Partnership
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/alternative-lenders">
                    <Typography type="subject">Non-Bank Lenders</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Get connected with us to efficiently find borrowers
                      currently in need
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/strategic-partners">
                    <Typography type="subject">Strategic Partners</Typography>
                    <NavDropdown.ItemText className="text-wrap">
                      Join forces with Fundingverse to help get your community
                      the funding they need
                    </NavDropdown.ItemText>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavDropdown
                title="About Us"
                id="  About-Us-nav-dropdown"
              >
                <div className="fnavbar-drop">
                  <NavDropdown.Item as={Link} to="/our-team">
                    <Typography type="subject">
                      Our Team
                    </Typography>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mission-statement">
                    <Typography type="subject">
                      Our Mission
                    </Typography>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <Typography type="navlink">
                <div onClick={() => setModalOpen(true)}>Contact</div>
              </Typography>
              <div className="d-flex align-items-center">
                <Button
                  className="text-light ms-3"
                  target="_blank"
                  href="https://portal.fundingverse.com"
                >
                  Login
                </Button>
              </div>
            </Nav>
          </div>
        </Container>
        <div className="mx-auto hide-on-desktop">
          <Nav className=" align-items-center">
            <div className="d-flex justify-content-center">
              <Typography type="navlink" to="/" className="mx-2">
                Home
              </Typography>
              <div
                className="mx-2 nav-item dropdown"
                onClick={() => {
                  setLearnOpen(!learnOpen);
                  setPartnerOpen(false);
                }}
              >
                <a className="dropdown-toggle nav-link">Loan Type</a>
              </div>
              <div
                className="mx-2 nav-item dropdown"
                onClick={() => {
                  setPartnerOpen(!partnerOpen);
                  setLearnOpen(false);
                }}
              >
                <a className="dropdown-toggle nav-link">Partner With Us</a>
              </div>
            </div>
            <div className="d-flex justify-content-center">
            <NavDropdown
                title="About Us"
                id="  About-Us-nav-dropdown"
              >
                <div className="fnavbar-drop">
                  <NavDropdown.Item as={Link} to="/our-team">
                    <Typography type="subject">
                      Our Team
                    </Typography>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mission-statement">
                    <Typography type="subject">
                      Our Mission
                    </Typography>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>]
            </div>
            <div
              hidden={!learnOpen}
              className="mt-3"
              style={{ maxHeight: "316px", overflowY: "scroll" }}
            >
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/business-line-of-credit"
              >
                <Typography type="subject">Business Line of Credit</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  A business line of credit is a business loan that offers the
                  flexibility to borrow up to a certain credit limit
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/working-capital-loan"
              >
                <Typography type="subject">Working Capital Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  A working capital loan is best used to fund short-term, daily
                  operational expenses such as payroll and purchasing materials.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/bank-loan"
              >
                <Typography type="subject">Bank Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  A Bank loan is best used to fund short-term, daily operational
                  expenses such as payroll and purchasing materials.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/credit-union-loan"
              >
                <Typography type="subject">Credit Union Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  A Credit Union Loan is much like a bank loan, and requires you
                  become a member of a Union.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/invoice-factoring-loan"
              >
                <Typography type="subject">Invoice Factoring Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Invoice Factoring loans are a great way to free up cash flow
                  for small businessess looking to stay afloat and continue
                  ascending.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/business-loan"
              >
                <Typography type="subject">Business Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Business loans are a great way for startup business with
                  little to no history to obtain funding and begin generating
                  revenue.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="text-light"
                to="/learn/term-loan"
              >
                <Typography type="subject">Term Loan</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Depending on the size, term loans can be used for expanding
                  human capital, construction, and inventory purchases.
                </NavDropdown.ItemText>
              </NavDropdown.Item>
            </div>
            <div hidden={!partnerOpen} className="text-light mt-3">
              <NavDropdown.Item
                as={Link}
                to="/loan-officers"
                className="text-light"
              >
                <Typography type="subject">Individual Loan Officers</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Partner with Fundingverse as an Individual Loan Officer
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/banks" className="text-light">
                <Typography type="subject">
                  Banks &amp; Credit Unions
                </Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Learn more about the Bank-Fundingverse Partnership
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/alternative-lenders"
                className="text-light"
              >
                <Typography type="subject">Non-Bank Lenders</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Get connected with us to efficiently find borrowers currently
                  in need
                </NavDropdown.ItemText>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/strategic-partners"
                className="text-light"
              >
                <Typography type="subject">Strategic Partners</Typography>
                <NavDropdown.ItemText className="text-wrap text-light">
                  Join forces with Fundingverse to help get your community the
                  funding they need
                </NavDropdown.ItemText>
              </NavDropdown.Item>
            </div>
            <div className="d-flex align-items-center">
                <Button
                  className="text-light ms-3"
                  target="_blank"
                  href="https://portal.fundingverse.com"
                >
                  Login
                </Button>
              </div>
          </Nav>
        </div>
      </Navbar>
    </>
    // <nav className="f-navigatior">
    // 	<div className="fundingverse-logo">
    // 		{/* TODO: Pull Image from CDN */}
    // 		<StaticImage
    // 			src="../images/fundingverse-logo-white.png"
    // 			alt="Fundingverse Logo"
    // 			placeholder="blurred"
    // 			layout="fixed"
    // 			width={200}
    // 			height={50}
    // 		/>
    // 	</div>
    // 	<div className="nav-links">
    // 		<Link to="/" className="link">Home</Link>
    // 		<a className="link">Learn</a>
    // 		<a className="link">Services</a>
    // 		<Link to="/features" className="link">Features</Link>
    // 		<Link to="/contact" className="link">Contact</Link>
    // 	</div>
    // 	<div className="menu-button">
    // 		<button className={`hamburger hamburger--spin${isMenuOpen ? " is-active" : ""}`} type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
    // 			<span className="hamburger-box">
    // 				<span className="hamburger-inner"></span>
    // 			</span>
    // 		</button>
    // 		<p>Menu</p>
    // 	</div>
    // </nav>
  );
}
