import React from "react";
import Footer from "./footer";
import NavigationBar from "./navigation";
import "../scss/index.scss";
import SEO from "./seo";

interface LayoutProps {
	children: React.ReactNode;
	nextPage: { link: string; text: string };
	title?: string;
	article?: boolean;
	description?: string;
	image?: string;
}

export default function Layout({
	children,
	nextPage,
	title = "Fundingverse",
	article = false,
	description = "A New Wave of Funding Opportunities",
  image = "",
}: LayoutProps) {
	return (
		<main>
			<SEO title={title} article={article} description={description} image={image} />
			<NavigationBar />
			{children}
			<Footer nextPage={nextPage} />
		</main>
	);
}
