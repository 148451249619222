import { Link } from "gatsby";
import * as React from "react";
import Nav from "react-bootstrap/esm/Nav";

interface TypographyProps {
	children: React.ReactNode;
	type: "head" | "title" | "subtitle" | "subject" | "paragraph" | "navlink";
	to?: string;
	color?: "light" | "dark";
	className?: string;
}

export default function Typography(props: TypographyProps) {
	const color: string = props.color ? `text-${props.color}` : "";
	let textClassName: string = `text-${props.type} ${color} ${props.className}`;
	switch (props.type) {
		case "head":
			return <h1 className={textClassName}>{props.children}</h1>;
		case "title":
			return <h2 className={textClassName}>{props.children}</h2>;
		case "subtitle":
			return <h3 className={textClassName}>{props.children}</h3>;
		case "subject":
			return <h4 className={textClassName}>{props.children}</h4>;
		case "paragraph":
			return <p className={`m-0 ${textClassName}`}>{props.children}</p>;
		case "navlink":
      textClassName = textClassName.concat(" d-flex align-items-center");
			if (props.to) {
				return (
					<Nav.Link>
						<Link to={props.to} className={`py-0 ${textClassName} nav-link`}>
							{props.children}
						</Link>
					</Nav.Link>
				);
			}
			return (
				<Nav.Link>
					<Nav.Link className={`p-0 ${textClassName}`}>
						{props.children}
					</Nav.Link>
				</Nav.Link>
			);
	}
}
