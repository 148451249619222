import * as React from "react";

interface SectionProps {
	children: React.ReactNode;
	image?: string;
	size?: "xsm" | "sm" | "md" | "lg" | "xlg";
	bg?: "light" | "dark" | "primary";
	color?: "light" | "dark";
	staticHeight?: boolean;
	className?: string;
}

export default function Section({
	children,
	image: background,
	size = "md",
	color = "dark",
	bg = "light",
	staticHeight,
	className,
}: SectionProps) {
	const isStatic: string = staticHeight ? "-static" : "";
	let sectionClassName: string = `section--${size}${isStatic} bg-${bg}`;
	if (background) sectionClassName = sectionClassName.concat(" section--bg");
	return <div className={`${sectionClassName} text-${color} ${className || ""}`}>{children}</div>;
}
