import React from "react";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";
import { Row, InputGroup, FormSelect } from "react-bootstrap";
import InputMask from "react-input-mask";

interface IframeValues {
  applicantName: string;
  applicantPhoneNumber: string;
  applicantEmail: string;
  businessName: string;
  industry: string;
  requestedLoanAmount: string;
  rangeWhenFundsNeeded: string;
  loanType: string;
}

export const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default function Iframe() {
  const initialValues: IframeValues = {
    applicantName: "",
    applicantPhoneNumber: "",
    applicantEmail: "",
    businessName: "",
    industry: "",
    requestedLoanAmount: "",
    rangeWhenFundsNeeded: "timing_not_important",
    loanType: "",
  };

  const handleLoanTypeChange = (
    e: React.FormEvent<HTMLSelectElement>,
    values: IframeValues,
    setValues: (
      values: React.SetStateAction<IframeValues>,
      shouldValidate?: boolean | undefined
    ) => void,
    handleChange: (e: React.FormEvent<HTMLSelectElement>) => void
  ) => {
    const val: string = (e.target as any).value; // Gets Values of loanType dropdown
    const newVal = { ...values } as IframeValues; // Shallow copies values variable
    newVal.loanType = val;
    handleChange(e);
    if (val === "construction")
      setValues({
        ...newVal,
        industry: "construction",
      });
    else if (val === "commercial_real_estate")
      setValues({
        ...newVal,
        industry: "real_estate_investor",
      });
  };

  const handleRequestedLoanAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    const val = e.target.value.split(",").join("");
    let newLoanAmount = Number(val).toLocaleString("en-US");
    e.target.value = newLoanAmount;
    handleChange(e);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validate={(values) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const errors: any = {};
          if (!values.applicantName) {
            errors.applicantName = "Name Required";
          }
          if (
            !values.applicantEmail ||
            !emailRegex.test(values.applicantEmail)
          ) {
            errors.applicantEmail = "Valid Email Required";
          }
          if (!values.applicantPhoneNumber) {
            errors.applicantPhoneNumber = "Phone Number Required";
          }
          if (!values.requestedLoanAmount) {
            errors.requestedLoanAmount = "Requested Loan Amount Required";
          }
          if (!values.rangeWhenFundsNeeded) {
            errors.rangeWhenFundsNeeded = "Funds Needed By Required";
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "iframeForm", ...values }),
          })
            .then(() => {
              alert("Thank You! Your Request Has Been Submitted.");
              actions.resetForm();
              actions.setSubmitting(false);
            })
            .catch(() => {
              alert("Error, Please Try Filling Out The Form Again");
            });
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setValues,
        }: FormikProps<IframeValues>) => (
          <Form name="iframeForm" className="w-100" data-netlify={true}>
            <Row className="mb-3">
              {() => console.log(values)}
              <div className="col form-group text-center">
                <label htmlFor="loanType">
                  
                </label>
                <Field 
                  render={() => {
                    return (
                      <div className = "w-50 m-auto">
                    
                      <FormSelect 
                      className="text-center"
                        onChange={(event) =>
                          handleLoanTypeChange(
                            event,
                            values,
                            setValues,
                            handleChange
                          )
                        }
                        onBlur={handleBlur}
                        id="loanType"
                        name="loanType"
                        size="lg"
                      >
                        <option className="text-center" hidden>
                        What type of loan do you need?
                        </option>
                        <option value="commercial_industrial">
                          Business Loan
                        </option>
                        <option value="commercial_real_estate">
                          Commercial Real Estate
                        </option>
                        <option value="construction">Construction Loan</option>
                        <option value="residential">
                          Residential Mortgage
                        </option>
                      </FormSelect>
                    </div>
                    );
                  }}
                />
                <div className="text-danger"></div>
              </div>
            </Row>
            <Row className="mb-3" hidden={!values.loanType}>
              <div>
                <label htmlFor="applicantName">
                  Full Name
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <Field
                  type="text"
                  className="form-control form-control-lg"
                  id="applicantName"
                  name="applicantName"
                  placeholder="Full name"
                  required
                />
                <small className="text-danger">
                  <ErrorMessage name="applicantName" />
                </small>
              </div>
              <div>
                <label htmlFor="applicantPhoneNumber">
                  Phone Number
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <Field
                  name="applicantPhoneNumber"
                  label="Phone Number"
                  render={({ field }: any) => (
                    <InputMask
                      {...field}
                      mask="(999) 999-9999"
                      placeholder="Phone number"
                      type="tel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control form-control-lg"
                    />
                  )}
                />
                <small className="text-danger">
                  <ErrorMessage name="applicantPhoneNumber" />
                </small>
              </div>
            </Row>
            <Row className="mb-3" hidden={!values.loanType}>
              <div className="flex-full" style={{ minWidth: "250px" }}>
                <label htmlFor="applicantEmail">
                  Email Address
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <Field
                  type="email"
                  className="form-control form-control-lg"
                  id="applicantEmail"
                  name="applicantEmail"
                  placeholder="Email Address"
                  required
                />
                <small className="text-danger">
                  <ErrorMessage name="applicantEmail" />
                </small>
              </div>
              <div className="flex-full" style={{ minWidth: "250px" }}>
                <label
                  htmlFor="businessName"
                  hidden={values.loanType === "residential"}
                >
                  Business Name
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <label
                  htmlFor="businessName"
                  hidden={values.loanType != "residential"}
                >
                  Business Name
                  <sup style={{ fontSize: "15px", color: "lightblue" }}>
                    (optional)
                  </sup>
                </label>
                <Field
                  type="text"
                  className="form-control form-control-lg"
                  id="businessName"
                  placeholder="Business Name"
                  name="businessName"
                  required={values.loanType != "residential"}
                />
              </div>
              <div
                className="flex-full"
                style={{ minWidth: "250px" }}
                hidden={values.loanType === "residential"}
              >
                <label htmlFor="industry" className="text-left">
                  Business Industry
                  <sup style={{ fontSize: "15px", color: "lightblue" }}>
                    (optional)
                  </sup>
                </label>
                <Field
                  render={() => {
                    return (
                      <FormSelect
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.industry}
                        id="industry"
                        name="industry"
                        size="lg"
                      >
                        <option></option>
                        <option value="accounting">Accounting</option>
                        <option value="automotive_aerospace">
                          Automotive/Aerospace
                        </option>
                        <option value="banking_finance">Banking/Finance</option>
                        <option value="business_consulting">
                          Business Consulting
                        </option>
                        <option value="construction">Construction</option>
                        <option value="culinary_restaurants">
                          Culinary/Restaurants
                        </option>
                        <option value="daycare">Daycare</option>
                        <option value="education">Education</option>
                        <option value="engineering">Engineering</option>
                        <option value="fashion_retail">Fashion/Retail</option>
                        <option value="franchise">Franchise</option>
                        <option value="government">Government</option>
                        <option value="insurance">Insurance</option>
                        <option value="it_services_software">
                          It Services/Software
                        </option>
                        <option value="hospitality_hotels">
                          Hospitality/Hotels
                        </option>
                        <option value="legal">Legal</option>
                        <option value="manufacturing">Manufacturing</option>
                        <option value="medical_healthcare">
                          Medical/Healthcare
                        </option>
                        <option value="military_defense">
                          Military/Defense
                        </option>
                        <option value="real_estate_investor">
                          Real Estate Investor
                        </option>
                        <option value="transport_logistics">
                          Transport/Logistics
                        </option>
                        <option value="other">Other/Not Listed</option>
                      </FormSelect>
                    );
                  }}
                />
              </div>
            </Row>
            <Row className="mb-3" hidden={!values.loanType}>
              <div className="flex-full" style={{ minWidth: "250px" }}>
                <label htmlFor="requestedLoanAmount">
                  Requested Loan Amount
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Field
                    render={() => {
                      return (
                        <input
                          onChange={(event) =>
                            handleRequestedLoanAmountChange(
                              event,
                              handleChange
                            )
                          }
                          onBlur={handleBlur}
                          className="form-control form-control-lg"
                          id="requestedLoanAmount"
                          name="requestedLoanAmount"
                          required
                        ></input>
                      );
                    }}
                  />
                </InputGroup>
                <small className="text-danger">
                  <ErrorMessage name="requestedLoanAmount" />
                </small>
              </div>
              <div className="flex-full" style={{ minWidth: "250px" }}>
                <label htmlFor="rangeWhenFundsNeeded">
                  How Soon Are Funds Needed?
                  <sup className="text-danger">
                    <b> *</b>
                  </sup>
                </label>
                <Field
                  render={() => {
                    return (
                      <FormSelect
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="rangeWhenFundsNeeded"
                        name="rangeWhenFundsNeeded"
                        size="lg"
                      >
                        <option value="timing_not_important">
                          Timing Not Important
                        </option>
                        <option value="one_to_three_days">1-3 Days</option>
                        <option value="less_than_one_week">
                          Less Than 1 Week
                        </option>
                        <option value="less_than_two_weeks">
                          Less Than 2 Weeks
                        </option>
                        <option value="two_or_more_weeks">2-4 Weeks</option>
                      </FormSelect>
                    );
                  }}
                />
              </div>
            </Row>
            <Row className="mb-3" hidden={!values.loanType}>
              <div className="flex-full" style={{ minWidth: "250px" }}>
                <label htmlFor="notes">Notes</label>
                <Field
                  className="form-control form-control-lg"
                  id="notes"
                  name="notes"
                  as="textarea"
                ></Field>
              </div>
            </Row>
            <div className="form-row mt-4" hidden={!values.loanType}>
              <div className="col text-end">
                <button type="submit">Submit</button>
                <div className="text-right"></div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
