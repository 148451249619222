import { Link } from "gatsby";
import React from "react";
import { Modal } from "react-bootstrap";
import Iframe from "./iframe";
import Typography from "./typography";

interface FooterProps {
  nextPage: { link: string; text: string };
}
// TODO Finish Privacy Policy & Terms Agreement

export default function Footer({ nextPage }: FooterProps) {
  const [iframeOpen, setIframeOpen] = React.useState<boolean>(false);
  const [footerTextOpen, setFooterTextOpen] = React.useState<
    "privacy_policy" | "terms_agreement" | ""
  >("");

  let footerText;
  if (footerTextOpen === "privacy_policy") {
    footerText = (
      <div>
        <h3>Fundingverse Privacy Policy</h3>
        <small>Effective July 6, 2021</small>
        <Typography type="paragraph" className="my-3">
          This privacy policy discloses the privacy practices for Fundingverse,
          Inc. (“Fundingverse,” “Fundingverse.com”) and applies solely to
          information in relation to the websites, products, services and
          programs owned and operated by Fundingverse.
        </Typography>
        <Typography type="paragraph" className="my-3">
          We reserve the right to change this privacy policy from time to time.
          The privacy policy is subject to and incorporates by reference our
          terms of use, including its limitation on liability.
        </Typography>
        <Typography type="paragraph" className="my-3">
          Your use of our site and submission of personal information online
          constitutes acceptance and understanding of this policy as in effect
          at the time of your submission.
        </Typography>
        <Typography type="paragraph" className="my-3">
          Fundingverse.com understands that you care about how we collect, use,
          and share information when you interact with our websites, social
          media sites and handles, email, and online services, and we value the
          trust you place in us.
        </Typography>
        <h4>Types of Personal Information We Collect</h4>
        <Typography type="paragraph" className="my-3">
          We do not collect personal information from visitors to our site
          unless you voluntarily decide to transmit it to us through one of the
          site features, provided email addresses, or as part of an application.
          Certain features of our site may require you to create an account.
          When you create an account with us, we may collect your name, email
          address, contact information, a username and password that you will
          use to access your account and other information. In addition, to
          submit a question, comment, or post to a blog, you may be required to
          submit your name and email address, and we may also ask you for other
          optional information, such as your business’s website address.
        </Typography>
        <h4>What We Do With the Personal Information Collected</h4>
        <Typography type="paragraph" className="my-3">
          Apart from using your personal information to support our own business
          functions, we may disclose your personal information under the
          following conditions:
        </Typography>
        <Typography type="paragraph" className="my-3">
          Disclosure of personal information to third parties. Fundingverse will
          not rent or sell your personal information to others but may disclose
          personal information with third-party vendors and service providers
          that work with Fundingverse. We will only share personal information
          to these vendors and service providers to help us provide a product or
          service to you. Examples of third parties we work with are background
          check agencies, benefits providers, financial institutions and other
          service providers that provide corporate functions to us such as human
          resources and customer relationship management. These third parties
          only have access to personal information necessary for them to perform
          their services.
        </Typography>
        <Typography type="paragraph" className="my-3">
          Disclosure of personal information for business purposes. In the event
          of corporate divestitures, mergers, acquisitions, bankruptcies,
          dissolutions, reorganizations, liquidations, or general business
          transactions or proceedings, Fundingverse may buy, sell, divest or
          transfer your personal information.
        </Typography>
        <Typography type="paragraph" className="my-3">
          Disclosure of personal information via links to third party services
          and applications. Using our websites or services may link you to third
          party websites, services, and applications. Fundingverse is not
          responsible for any personal information collected by such third
          parties which use is governed through the third party's website's
          privacy policies. Any interactions you have with these websites,
          services, or applications are beyond the control of Fundingverse. When
          you post information to or through such services, those websites'
          privacy policies apply. We urge you to read the privacy and security
          policies of any external websites before providing any personal
          information while accessing those websites.
        </Typography>
        <h4>Customer Data</h4>
        <Typography type="paragraph" className="my-3">
          Fundingverse customers may electronically submit data or information
          to us for hosting and processing purposes ("Customer Data"). In
          accordance with the Fundingverse privacy policy, Fundingverse will
          access customer data only for providing our products and services or
          for preventing or addressing service or technical problems (at a
          customer's request in connection with customer support matters), or as
          may be required by law. Fundingverse will not otherwise review, share,
          distribute, or reference any customer data except as authorized by the
          applicable customer.
        </Typography>
        <Typography type="paragraph" className="my-3">
          Fundingverse may transfer personal information to third party
          companies that help us provide our products and services. Transfers to
          subsequent third parties are covered by the provisions in this privacy
          policy regarding notice and choice and the service agreements with our
          customers. Fundingverse maintains contracts with these third parties
          restricting their access, use and disclosure of personal data in
          compliance with our privacy obligations, and remains liable if they
          fail to meet those obligations. We are responsible for the event
          giving rise to damage.
        </Typography>
      </div>
    );
  } else {
    footerText = (
      <div>
        <div></div>
      </div>
    );
  }

  return (
    <>
      <Modal
        show={iframeOpen}
        onHide={() => setIframeOpen(false)}
        size="xl"
        centered
      >
        <Modal.Body>
          <Iframe />
        </Modal.Body>
      </Modal>
      <Modal
        show={!!footerTextOpen}
        onHide={() => setFooterTextOpen("")}
        size="xl"
        centered
      >
        <Modal.Body>{footerText}</Modal.Body>
      </Modal>
      <footer className="footer text-light bg-dark">
        {/* <div className="footer__content">
				<div
					className="trustpilot-widget"
					data-locale="en-US"
					data-template-id="56278e9abfbbba0bdcd568bc"
					data-businessunit-id="60e0cf914f5f3b000119702f"
					// data-style-height="130px"
					data-style-width="100%"
					data-theme="light"
					data-stars="5"
					data-schema-type="Organization"
					style={{ position: "relative" }}>
					<iframe
						title="Customer reviews powered by Trustpilot"
						loading="lazy"
						src="https://widget.trustpilot.com/trustboxes/53aa8912dec7e10d38f59f36/index.html?templateId=56278e9abfbbba0bdcd568bc;businessunitId=60e0cf914f5f3b000119702f#locale=en-US;styleHeight=130px;styleWidth=100%25;theme=light;stars=5;schemaType=Organization"
						style={{
							position: "relative",
							height: "130px",
							width: "100%",
							borderStyle: "none",
							display: "block",
							overflow: "hidden",
						}}
					/>
				</div>
			</div> */}
        <div className="footer__content">
          <div className="footer__main hide-on-mobile">
            <div className="footer__wording">
              <h1>Get Started with a Fundingverse Representative</h1>
              <p>
                Connect With Us To See What a Fundingverse
                <br /> Partnership Can Do For You
              </p>
            </div>
            <Link to="/">
              <button
                className="btn--primary btn--lg"
                onClick={() => setIframeOpen(true)}
              >
                Click Here To Get Started
              </button>
            </Link>
          </div>
          <div className="footer__explore mb-4">
            <h3>Explore</h3>
            <div>
              <div>
                <Link to="/" className="link link--page">
                  Home
                </Link>
              </div>
              <div>
                <Link to="/our-team" className="link link--page">
                  Our Team
                </Link>
              </div>
              <div>
                <Link to="/banks" className="link link--page">
                  For Banks &amp; Credit Unions
                </Link>
              </div>
              <div>
                <Link to="/loan-officers" className="link link--page">
                  For Loan Officers
                </Link>
              </div>
              <div>
                <Link to="/strategic-partners" className="link link--page">
                  For Strategic Partners
                </Link>
              </div>
            </div>
          </div>
          <div className="footer__explore">
            <h4>Contact</h4>
            <div>
              <div>
                <a className="link link--page mb-2" href="tel:888-420-8686">
                  Phone: 888-420-8686
                </a>
              </div>
              <div>
                <a
                  className="link link--page mb-2"
                  href="mailto:questions@fundingverse.com"
                >
                  Email: questions@fundingverse.com
                </a>
              </div>
              <div className="link link--page">
                Address: 4470 Chamblee Dunwoody Rd, Atlanta, GA 30338
              </div>
            </div>
          </div>
        </div>
        <div className="footer__button">
          <p
            className="link link--page"
            style={{ cursor: "pointer" }}
            onClick={() => setFooterTextOpen("privacy_policy")}
          >
            Privacy Policy
          </p>
          <p className="mx-5 link link--page">Terms Agreement</p>
        </div>
      </footer>
    </>
  );
}
